import { APICore } from './apiCore';

const api = new APICore();

function bookingList(): any {
    const baseUrl = '/eventbookings/bookings';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function bookingShow(params: any): any {
    const baseUrl = '/eventbookings/bookings/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function bookingCreateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.id + '/eventbooking_comments';
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bookingUpdateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.eventbooking_id + '/eventbooking_comments/'+ requestBody.id;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bookingDeleteComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.eventbooking_id + '/eventbooking_comments/'+ requestBody.id;
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bookingFilter(params: any): any {
    const baseUrl = '/eventbookings/bookings';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
        new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { bookingList, bookingShow, bookingFilter, bookingCreateComment,  bookingUpdateComment, bookingDeleteComment };