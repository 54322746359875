import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ChangePassword = React.lazy(() => import('../pages/account/ChangePassword'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));
const SelectAccount = React.lazy(() => import('../pages/account/SelectAccount'));


// apps
const Eventbookings = React.lazy(() => import('../pages/eventbookings/index'));
const ShowEventbooking = React.lazy(() => import('../pages/eventbookings/EventbookingShow'));

const Bookings = React.lazy(() => import('../pages/bookings/index'));
const ShowBooking = React.lazy(() => import('../pages/bookings/bookingShow'));

const BookingRequests = React.lazy(() => import('../pages/booking_requests/index'));
const ShowBookingRequest = React.lazy(() => import('../pages/booking_requests/BookingrequestShow'));

const WaitingLists = React.lazy(() => import('../pages/waitinglist/index'));
const ShowWaitingList = React.lazy(() => import('../pages/waitinglist/WaitinglistShow'));

const Events = React.lazy(() => import('../pages/events/index'));
const ShowEvent = React.lazy(() => import('../pages/events/Show'));

const EventCoaches = React.lazy(() => import('../pages/event_coaches/index'));
const ShowEventCoach = React.lazy(() => import('../pages/event_coaches/Show'));

const Surveys = React.lazy(() => import('../pages/surveys/index'));
const ShowSurvey = React.lazy(() => import('../pages/surveys/Show'));

const Assets = React.lazy(() => import('../pages/assets/index'));
const ShowAsset = React.lazy(() => import('../pages/assets/Show'));

const DigitalProducts = React.lazy(() => import('../pages/digital_products/index'));

const Businessdocuments = React.lazy(() => import('../pages/businessdocuments/index'));
const BusinessdocumentShow = React.lazy(() => import('../pages/businessdocuments/Show'));

const Dashboard = React.lazy(() => import('../pages/dashboard/index'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));


const loading = () => <div className=""></div>;

type
LoadComponentProps = {
    component: React.LazyExoticComponent < ()
=>
JSX.Element >,
}
;

const LoadComponent = ({component: Component}: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component/>
    </Suspense>
);

const AllRoutes = () => {

    const {layout} = useSelector((state) => ({
        layout: state.Layout
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {path:  "/", element: <Root/>},
        {
            // public routes
            path:  "/",
            element: <DefaultLayout/>,
            children: [
                {
                    path: '',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'select_account', element: <LoadComponent component={SelectAccount}/>},
                        {path: 'register', element: <LoadComponent component={Register}/>},
                        {path: 'confirm', element: <LoadComponent component={Confirm}/>},
                        {path: 'passwords/new', element: <LoadComponent component={ForgetPassword}/>},
                        {path: 'passwords/:reset_token', element: <LoadComponent component={ChangePassword}/>},
                        {path: 'lock-screen', element: <LoadComponent component={LockScreen}/>},
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound}/>,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError}/>,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance}/>,
                },
            ],
        },
        {
            // auth protected routes
            path:  '/',
            element: <PrivateRoute roles={'Admin'} component={Layout}/>,
            children: [
                {
                    path: '/dashboard',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Dashboard}/>,
                        }
                    ]
                },
                {
                    path: '/events',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Events}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowEvent}/>,
                        },
                    ],
                },
                {
                    path: '/waiting_lists',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={WaitingLists}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowWaitingList}/>,
                        },
                    ],
                },
                {
                    path: '/event_coaches',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={EventCoaches}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowEventCoach}/>,
                        },
                    ],
                },
                {
                    path: '/surveys',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Surveys}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowSurvey}/>,
                        },
                    ],
                },
                {
                    path: '/booking_requests',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={BookingRequests}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowBookingRequest}/>,
                        },
                    ],
                },
                {
                    path: '/digital_products',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={DigitalProducts}/>,
                        }
                    ],
                },
                {
                    path: '/assets',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Assets}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowAsset}/>,
                        },
                    ],
                },
                {
                    path: '/eventbookings',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Eventbookings}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowEventbooking}/>,
                        },
                    ],
                },
                {
                    path: '/bookings',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Bookings}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ShowBooking}/>,
                        },
                    ],
                },
                {
                    path: '/businessdocuments',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Businessdocuments}/>,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={BusinessdocumentShow}/>,
                        },
                    ],
                }
            ]
        },
    ]);
};

export {AllRoutes};
