import { BookingActionTypes } from './constants';

const BOOKING_STATE = {
    eventbookings: [],
    eventbooking: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type BookingAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { booking?: {} | null, loading?: boolean, +value?: boolean };

const BookingReducer = (state: State = BOOKING_STATE, action: bookingAction): any => {
    switch (action.type) {
        case BookingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BookingActionTypes.LIST_BOOKING: {
                    return {
                        ...state,
                        eventbookings: action.payload.data.eventbookings,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case BookingActionTypes.SHOW_BOOKING: {
                    return {
                        ...state,
                        eventbooking: action.payload.data,
                        loading: false,
                    };
                }
                case BookingActionTypes.CREATE_COMMENT: {
                    return {
                        ...state,
                        eventbooking: action.payload.data.eventbooking,
                        loading: false,
                    };
                }
                case BookingActionTypes.UPDATE_COMMENT: {
                    return {
                        ...state,
                        eventbooking: action.payload.data.eventbooking,
                        loading: false,
                    };
                }
                case BookingActionTypes.DELETE_COMMENT: {
                    return {
                        ...state,
                        eventbooking: action.payload.data.eventbooking,
                        loading: false,
                    };
                }
                case BookingActionTypes.FILTER_BOOKING: {
                    return {
                        ...state,
                        eventbookings: action.payload.data.eventbookings,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BookingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BookingActionTypes.LIST_BOOKING: {
                    return {
                        ...state,
                        error: action.payload.error,
                        eventbookings: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case BookingActionTypes.SHOW_BOOKING: {
                    return {
                        ...state,
                        error: action.payload.error,
                        eventbooking: "",
                        loading: false,
                    };
                }
                case BookingActionTypes.FILTER_BOOKING: {
                    return {
                        ...state,
                        eventbookings: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BookingActionTypes.LIST_BOOKING:
            return { ...state, loading: true, eventbookings: [], total_pages: 1 };
        case BookingActionTypes.SHOW_BOOKING:
            return { ...state, loading: true, eventbooking: "" };
        case BookingActionTypes.FILTER_BOOKING:
            return { ...state, loading: true, eventbookings: state.eventbookings, filters: state.filters };
        default:
            return { ...state };
    }
};

export default BookingReducer;