import { BookingActionTypes } from './constants';

export const listBookings = (actionType: string, data: any): BookingAction => ({
    type: BookingActionTypes.LIST_BOOKING,
    payload: { data },
});

export const filterBookings = (data: any): BookingAction => ({
    type: BookingActionTypes.FILTER_BOOKING,
    payload: { data },
});

export const createBookingComment = (data, id): EventbookingAction => ({
    type: BookingActionTypes.CREATE_COMMENT,
    payload: { data, id},
});

export const updatebookingComment = (data, id, booking_id) => ({
    type: BookingActionTypes.UPDATE_COMMENT,
    payload: { data, id, booking_id },
});

export const deletebookingComment = (id, booking_id) => ({
    type: BookingActionTypes.DELETE_COMMENT,
    payload: { id, booking_id },
});

export const showBookings = (data: any): BookingAction => ({
    type: BookingActionTypes.SHOW_BOOKING,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): BookingAction => ({
    type: BookingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): BookingAction => ({
    type: BookingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

