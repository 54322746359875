
export const BookingActionTypes = {
    API_RESPONSE_SUCCESS: '@@booking/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@booking/API_RESPONSE_ERROR',

    LIST_BOOKING: '@@booking/LIST_BOOKING',
    SHOW_BOOKING: '@@booking/SHOW_BOOKING',
    CREATE_COMMENT: '@@booking/CREATE_COMMENT',
    UPDATE_COMMENT: '@@booking/UPDATE_COMMENT',
    DELETE_COMMENT: '@@booking/DELETE_COMMENT',
    FILTER_BOOKING: '@@booking/FILTER_BOOKING',
};