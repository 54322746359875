// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    bookingList as bookingListApi,
    bookingShow as bookingShowApi,
    bookingFilter as bookingFilterApi,
    bookingCreateComment as bookingCreateCommentApi,
    bookingUpdateComment as bookingUpdateCommentApi,
    bookingDeleteComment as bookingDeleteCommentApi
} from "../../../helpers/api/booking"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { BookingActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(bookingListApi);
        const eventbookings = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(BookingActionTypes.LIST_BOOKING, eventbookings));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(BookingActionTypes.LIST_BOOKING, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(bookingFilterApi, { data });
        const eventbookings = response.data;

        yield put(authApiResponseSuccess(BookingActionTypes.FILTER_BOOKING, eventbookings));
    } catch (error) {
        yield put(authApiResponseError(BookingActionTypes.FILTER_BOOKING, error));
    }
}

function* createComment(action) {

    try {
        const response = yield call(bookingCreateCommentApi, {id: action.payload.id, data: action.payload.data});


        yield put(authApiResponseSuccess(BookingActionTypes.CREATE_COMMENT, {eventbooking: response.data.data.eventbooking, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(BookingActionTypes.CREATE_COMMENT, error));
    }
}

function* updateComment(action) {

    try {
        const response = yield call(bookingUpdateCommentApi, {id: action.payload.id, eventbooking_id: action.payload.eventbooking_id, data: action.payload.data});


        yield put(authApiResponseSuccess(BookingActionTypes.UPDATE_COMMENT, {id: action.payload.data, eventbooking: response.data.data.eventbooking }));
    } catch (error) {
        yield put(authApiResponseError(BookingActionTypes.UPDATE_COMMENT, error));
    }
}

function* deleteComment(action) {

    try {
        const response = yield call(bookingDeleteCommentApi, {id: action.payload.id, eventbooking_id: action.payload.eventbooking_id});

        yield put(authApiResponseSuccess(BookingActionTypes.DELETE_COMMENT, {id: action.payload.data, eventbooking: response.data.data.eventbooking }));
    } catch (error) {
        yield put(authApiResponseError(BookingActionTypes.DELETE_COMMENT, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(bookingShowApi, data);
        const eventbooking = response.data.eventbooking;

        yield put(authApiResponseSuccess(BookingActionTypes.SHOW_BOOKING, eventbooking));
    } catch (error) {
        yield put(authApiResponseError(BookingActionTypes.SHOW_BOOKING, error));
    }
}

export function* watchBookingList(): any {
    yield takeEvery(BookingActionTypes.LIST_BOOKING, list);
}

export function* watchBookingFilter(): any {
    yield takeEvery(BookingActionTypes.FILTER_BOOKING, filter);
}

export function* watchBookingShow(): any {
    yield takeEvery(BookingActionTypes.SHOW_BOOKING, show);
}

export function* watchBookingCreateComment(): any {
    yield takeEvery(BookingActionTypes.CREATE_COMMENT, createComment);
}

export function* watchBookingUpdateComment(): any {
    yield takeEvery(BookingActionTypes.UPDATE_COMMENT, updateComment);
}

export function* watchBookingDeleteComment(): any {
    yield takeEvery(BookingActionTypes.DELETE_COMMENT, deleteComment);
}


function* bookingSaga(): any {
    yield all([
        fork(watchBookingList),
        fork(watchBookingShow),
        fork(watchBookingFilter),
        fork(watchBookingCreateComment),
        fork(watchBookingUpdateComment),
        fork(watchBookingDeleteComment)
    ]);
}

export default bookingSaga;
